import { AuthenticationContext, AdalConfig, adalFetch } from "react-adal";

export const adalConfig: AdalConfig = {
  tenant: "81fa766e-a349-4867-8bf4-ab35e250a08f",
  clientId: "a2d2736f-a543-4e3b-80ee-5fd0ea415836",
  endpoints: {
    api: "a2d2736f-a543-4e3b-80ee-5fd0ea415836",
  },
  cacheLocation: "localStorage",
  redirectUri: window.location.origin,
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetcher: any, url: string, options: any) =>
  adalFetch(authContext, adalConfig.endpoints!.api, fetcher, url, options);

export const getToken = () =>
  authContext.getCachedToken(authContext.config.clientId);

export const getUserName = () => {
  let userName = "";
  authContext.getUser((err, user) => {
    if (!err && user) {
      userName = user.profile.name
        .split(",")
        .reverse()
        .map((s: string) => s.trim())
        .join(" ");
    }
  });
  return userName;
};

type AuthContextWithPrivate = AuthenticationContext & {
  _user: { userName: string };
};

export const getUserEmail = () =>
  (authContext as AuthContextWithPrivate)["_user"].userName || "";
